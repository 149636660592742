import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../effects/useAppSelector';
import { Table } from '../../bidding/common/table';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { SORTING_TYPE } from '../../../constants';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { EmptyPlaceholder, Preloader, OnHoverTooltip, FlexRow } from '../../common';
import { AppState } from '../../../types/state/AppState';
import IconSVG from '../../../styles/svg-icons';
import { TokenSearch } from './TokenSearch';
import { manageTokenActions } from '../../../actions/manage-tokens.actions';
import { ApiTokenStatus, AccessTokenApiLabels, AccessTokenStatusLabels, AccessToken, AccessTokenApi } from '../../../types/management/AccessToken';
import { AddEditTokenPopup } from './AddEditTokenPopup';
import { TokensFilter } from './TokensFilter';
import { useTokenFilter } from './useTokenFilter';
import { arrayUtils, isRequesting } from '../../../utils';
import { EmailLink } from '../../bidding/common/EmailLink';
import { Confirm } from '../../alerts';

const manageTokenDateTimeFormat = 'MM/DD/YYYY';

interface AccessTokenTableItem extends AccessToken {
    apiString: string;
    endDate: Date;
    createdByName: string;
}

const getApisLabels = (apis: AccessTokenApi[]) => {
    const bwicMonitor: AccessTokenApi[] = []
    const portfolio: AccessTokenApi[] = []
    const other: AccessTokenApi[] = []

    const formatGroup = (groupName: string, apis: AccessTokenApi[]) =>
        apis.length ? `${groupName} (${apis.map(a => AccessTokenApiLabels[a]).sort((a, b) => a.localeCompare(b)).join(", ")})` : "";

    arrayUtils
        .distinct(apis, x => x)
        .forEach(a => {
            if (a === AccessTokenApi.BwicMonitorApiHistoryReader || a === AccessTokenApi.BwicMonitorApiReader) {
                bwicMonitor.push(a);
            } else if (a === AccessTokenApi.PortfolioApiReader || a === AccessTokenApi.PortfolioApiWriter) {
                portfolio.push(a);
            } else {
                other.push(a)
            }
        });

    return [
        formatGroup("BWIC Monitor API", bwicMonitor),
        formatGroup("Portfolio API", portfolio),
        ...other.map(a => AccessTokenApiLabels[a])]
        .filter(x => x)
        .sort((a, b) => a.localeCompare(b))
        .join("/");
}

export const ManageTokens = () => {
    const dispatch = useDispatch();
    const lastAppliedTokenFilter = useSelector((state: AppState) => state.manageToken.lastAppliedTokenFilter);
    const searchTerm = useSelector((state: AppState) => state.manageToken.filter.searchTerm);
    const tokens = useSelector((state: AppState) => state.manageToken.tokens);
    const [filteredTokens, setFilteredTokens] = React.useState<AccessToken[]>([]);

    const requestState = useSelector((state: AppState) => state.manageToken.requestState);
    const [addEditTokenPopupVisible, setAddEditTokenPopupVisible] = useState(false);

    useTokenFilter(tokens, lastAppliedTokenFilter, searchTerm, setFilteredTokens);

    useEffect(() => {
        dispatch(manageTokenActions.requestTokens());

        return () => {
            dispatch(manageTokenActions.reset());
        }
    }, [dispatch]);

    const convertToTableItems = (tokens: AccessToken[]): AccessTokenTableItem[] =>
        tokens.map(t => ({
            ...t,
            apiString: [...t.apis].sort().join('/'),
            endDate: t.revokeDate ?? t.expirationDate,
            createdByName: t.createdBy.firstName + ' ' + t.createdBy.lastName
        }));

    const getTableColumns = () => {
        const columns: IColumnDefinition<AccessTokenTableItem>[] = [
            {
                columnKey: 'company',
                renderColumnHeaderContent: () => 'Company',
                headerClassName: 'data-list-cell-xl',
                renderColumnContent: entity => <>{
                    <OnHoverTooltip overlay={entity.company.name}>
                        {entity.company.name}
                    </OnHoverTooltip>
                }</>,
                bodyClassName: 'data-list-cell-xl',
                sortingField: 'company.name',
                sortingType: SORTING_TYPE.string
            }, {
                columnKey: 'tokenStatus',
                renderColumnHeaderContent: () => 'Token Status',
                renderColumnContent: token =>
                    <span className={"status status-" + AccessTokenStatusLabels[token.status].toLowerCase()}>
                        {AccessTokenStatusLabels[token.status]}
                    </span>,
                headerClassName: 'data-list-cell-sm cell-token-status',
                bodyClassName: 'data-list-cell-sm cell-token-status',
                sortingField: 'status',
                sortingType: SORTING_TYPE.number
            }, {
                columnKey: 'api',
                renderColumnHeaderContent: () => 'API',
                renderColumnContent: token =>
                    <OnHoverTooltip overlay={getApisLabels(token.apis)}>
                        {getApisLabels(token.apis)}
                    </OnHoverTooltip>,
                headerClassName: 'data-list-cell-xxxl-flexible',
                bodyClassName: 'data-list-cell-xxxl-flexible',
                sortingField: 'apiString',
                sortingType: SORTING_TYPE.string
            }, {
                columnKey: 'genBy',
                renderColumnHeaderContent: () => 'Gen. by',
                renderColumnContent: token => <>{token.createdByName}(<EmailLink email={token.createdBy.email} />)</>,
                headerClassName: 'data-list-cell-xxl-flexible',
                bodyClassName: 'data-list-cell-xxl-flexible',
                sortingType: SORTING_TYPE.string,
                sortingField: 'createdByName'
            }, {
                columnKey: 'genDate',
                renderColumnHeaderContent: () => 'Gen.Date',
                renderColumnContent: token => moment(token.creationDate).format(manageTokenDateTimeFormat),
                headerClassName: 'data-list-cell-sm',
                bodyClassName: 'data-list-cell-sm',
                sortingType: SORTING_TYPE.date,
                sortingField: 'creationDate'
            }, {
                columnKey: 'expRevDate',
                renderColumnHeaderContent: () => 'Exp./Rev. Date',
                renderColumnContent: token => moment(token.endDate).format(manageTokenDateTimeFormat),
                headerClassName: 'data-list-cell-sm',
                bodyClassName: 'data-list-cell-sm',
                sortingType: SORTING_TYPE.date,
                sortingField: 'endDate',
            }, {
                columnKey: 'options',
                renderColumnHeaderContent: () => 'Actions',
                renderColumnContent: token => <TokenActions token={token} />,
                headerClassName: 'data-list-cell-xs-sm text-right',
                bodyClassName: 'data-list-cell-xs-sm text-right'
            }
        ]
        return columns.map(c => new ColumnBuilder(c))
    }

    return (
        <div className="container access-tokens">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>API Access Tokens</h1>
                    <TokenSearch
                        searchTerm={searchTerm}
                        onSearchTermChange={(e) => dispatch(manageTokenActions.setSearchTerm(e))}
                    />
                    <div className="flex-row flex-item-right controls">
                        <button
                            className="btn btn-main flex-none"
                            onClick={() => setAddEditTokenPopupVisible(true)}
                        >
                            Generate New Token
                        </button>
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row">
                    <TokensFilter enabled={!isRequesting(requestState)} />
                </div>
            </div>
            <div className="position-relative flex-row flex-row-full-size">
                <Preloader inProgress={isRequesting(requestState)}>
                    {filteredTokens.length ? (
                        <Table
                            className="data-list-striped"
                            columns={getTableColumns()}
                            dataItems={convertToTableItems(filteredTokens)}
                        />
                    ) : (
                        <EmptyPlaceholder
                            text="There are no API Access Tokens generated. To generate API Access Token please click button below."
                        >
                            <button
                                className="btn btn-main flex-none"
                                onClick={() => setAddEditTokenPopupVisible(true)}
                            >
                                Generate New Token
                            </button>
                        </EmptyPlaceholder>
                    )}
                </Preloader>
            </div>
            {addEditTokenPopupVisible && <AddEditTokenPopup onClose={() => setAddEditTokenPopupVisible(false)} />}
        </div>
    )
}

interface TokenActionsProps {
    token: AccessToken;
}

function TokenActions({ token }: TokenActionsProps) {
    const dispatch = useDispatch();
    const revokeTokenRequestStatus = useAppSelector(s => s.manageToken.revokeTokenRequestStatus[token.id])
    const [isAddEditTokenPopupVisible, setIsAddEditTokenPopupVisible] = useState(false);
    const [isRevokePopupVisible, setIsRevokePopupVisible] = useState(false);
    const isDisabled = token.status !== ApiTokenStatus.Active;

    const handleRevoke = () => {
        dispatch(manageTokenActions.revoke(token.id));
        setIsRevokePopupVisible(false)
    }

    return (
        <>
            {isAddEditTokenPopupVisible &&
                <AddEditTokenPopup token={token} onClose={() => setIsAddEditTokenPopupVisible(false)} />}
            {
                isRevokePopupVisible &&
                <Confirm
                    onCancel={() => setIsRevokePopupVisible(false)}
                    onConfirm={handleRevoke}
                    title="Revoke Access Token"
                    text={`Are you sure you want to revoke the ${getApisLabels(token.apis)} access token for the ${token.company.name} company?`}
                />
            }

            <FlexRow className="justify-content-end actions-cntrls">
                <Preloader small={true} fullScreen={false} inProgress={isRequesting(revokeTokenRequestStatus)}>
                    <>
                        <div className="actions-cntrls-item">
                            <OnHoverTooltip disabled={isDisabled} overlay="Edit Access Token">
                                <button
                                    className="btn-link"
                                    disabled={isDisabled}
                                    onClick={() => setIsAddEditTokenPopupVisible(true)}
                                >
                                    <IconSVG name="edit-pencil" width={16} height={16} />
                                </button>
                            </OnHoverTooltip>
                        </div>
                        <div className="actions-cntrls-item">
                            <OnHoverTooltip disabled={isDisabled} overlay="Revoke Access Token">
                                <button
                                    className="btn-link btn-danger"
                                    disabled={isDisabled}
                                    onClick={() => setIsRevokePopupVisible(true)}
                                >
                                    <IconSVG name="close" width={16} height={16} />
                                </button>
                            </OnHoverTooltip>
                        </div>
                    </>
                </Preloader>
            </FlexRow>
        </>
    );
}
