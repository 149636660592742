import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { getFilteredParsedBWICs } from '../../../selectors';
import { BWIC } from './';
import { EmptyPlaceholder, InfiniteScrollLoader } from '../../common';
import { manageParsedBwicsActions as actions } from '../../../actions';
import { Confirm } from '../../alerts';
import { classnames } from '../../../styles/classnames';
import IconSVG from '../../../styles/svg-icons';


class BWICList extends Component {
    componentDidMount = () => this.ensureLastUpdatedTimeRefresh();

    componentDidUpdate = () => this.ensureLastUpdatedTimeRefresh();

    ensureLastUpdatedTimeRefresh = () => {
        const { bwics, dispatch } = this.props;
        const hasUpdatedBwics = bwics && bwics.length && bwics.some(b => b.lastUpdateAt);

        if (hasUpdatedBwics && !this.interval) {
            this.interval = setInterval(() => dispatch(actions.refreshLastUpdatedDate()), 20000);
        } else if (!hasUpdatedBwics && this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    componentWillUnmount = () => {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render = () => {
        const { bwics } = this.props;

        if (!bwics) {
            return null;
        }

        const {
            publishDialog,
            unpublishDialog,
            deleteBwicDialog,
            collapseAll,
            isNextPageRequesting,
            currentPageNumber,
            pageSize,
            totalRecordNumber,
            dispatch
        } = this.props;

        const collapseAllIconCssClasses = classnames({
            'collapse': !collapseAll
        });

        return (
            <div className="container-bwic-group-scroll container-flex">
                <div className="container-bwic-group-view">
                    {!bwics.length && <EmptyPlaceholder />}
                    {!!bwics.length &&
                        <>
                            <div className="data-table-header">
                                <div className="data-table-header-item data-table-item-collapse">
                                {!!bwics.length &&
                                    <span className="btn-link" onClick={() => dispatch(actions.setCollapseAll(!collapseAll))}>
                                        <IconSVG className={collapseAllIconCssClasses} name="icon-double-arrows" width={16} height={16} />
                                    </span>
                                    }
                                </div>
                                <div className="data-table-header-item data-table-item-ticker">Ticker</div>
                                <div className="data-table-header-item data-table-item-same-day"></div>
                                <div className="data-table-header-item data-table-item-isin">ISIN/CUSIP</div>
                                <div className="data-table-header-item data-table-item-asset">Asset</div>
                                <div className="data-table-header-item data-table-item-ccy">Ccy</div>
                                <div className="data-table-header-item data-table-item-rating">Rtg</div>
                                <div className="data-table-header-item data-table-item-rating">S&P/Fitch</div>
                                {/*<div className="data-table-header-item data-table-item-factor">Factor</div>*/}
                                <div className="data-table-header-item data-table-item-size">Size, MM</div>
                                <div className="data-table-header-item data-table-item-px text-right">Px Talk</div>
                                <div className="data-table-header-item data-table-item-color">Color</div>
                                <div className="data-table-header-item flex-item-right">Actions</div>
                            </div>
                            <div className="data-table-content container-flex">
                                <InfiniteScroll
                                    pageStart={0}
                                    initialLoad={false}
                                    loadMore={() => dispatch(actions.loadNextPage())}
                                    hasMore={(currentPageNumber * pageSize) < totalRecordNumber}
                                    loader={<InfiniteScrollLoader key={0} isNextPageRequesting={isNextPageRequesting}/>}
                                    useWindow={false}
                                >
                                    {!!bwics.length && bwics.map((b, index) => <BWIC key={index} source={b} />)}
                                </InfiniteScroll>
                            </div>
                        </>
                    }

                    {
                        publishDialog.visible &&
                        <Confirm
                            title="Publish BWIC"
                            text="The BWIC will be published. Would you like to proceed?"
                            onCancel={() => dispatch(actions.publishDialog(false))}
                            onConfirm={() => dispatch(actions.publish(publishDialog.bwic))}
                        />
                    }
                    {
                        unpublishDialog.visible &&
                        <Confirm
                            title="Unpublish BWIC"
                            text="The BWIC will be unpublished. Would you like to proceed?"
                            onCancel={() => dispatch(actions.unpublishDialog(false))}
                            onConfirm={() => dispatch(actions.unpublish(unpublishDialog.bwic))}
                        />
                    }
                    {
                        deleteBwicDialog.visible &&
                        <Confirm
                            title="Delete BWIC"
                            text="The BWIC will be deleted. Would you like to proceed?"
                            onCancel={() => dispatch(actions.deleteBwicDialog(false))}
                            onConfirm={() => dispatch(actions.deleteBwic(deleteBwicDialog.referenceName))}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ manageParsedBwics, searchSecurities }) => ({
    bwics: getFilteredParsedBWICs({ manageParsedBwics, searchSecurities }),
    publishDialog: manageParsedBwics.publishDialog,
    unpublishDialog: manageParsedBwics.unpublishDialog,
    deleteBwicDialog: manageParsedBwics.deleteBwicDialog,
    collapseAll: manageParsedBwics.collapseAll,
    searchInProgress: manageParsedBwics.searchInProgress,
    currentPageNumber: manageParsedBwics.currentPageNumber,
    pageSize: manageParsedBwics.pageSize,
    totalRecordNumber: manageParsedBwics.totalRecordNumber,
    isNextPageRequesting: manageParsedBwics.isNextPageRequesting,
});

const connectedBWICList = connect(mapStateToProps)(BWICList);
export { connectedBWICList as BWICList };
