import { useState } from 'react';
import { Link, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProfileMenu } from '../profile/profile-menu/ProfileMenu';
import { routes, roles } from '../../constants';
import { Support } from '../common';
import { AffirmTradePopup } from '../bidding/brokerDealer/trade-affirm';
import { TradeRejectReasonPopup } from '../bidding/brokerDealer/trade-affirm/TradeRejectReasonPopup';
import { ShowFor } from '../access';
import { Footer } from "./Footer";
import { InviteClientsPopup } from "../account";
import { UserFeedback } from '../userFeedback';
import { ContactSalesPopup } from '../contact-sales/ContactSalesPopup';
import { NavBarItem } from './NavBarItem';
import { TrialPeriodBanner } from '../subscription/TrialPeriodBanner';
import { WhatsNewPopup } from '../whats-new-popup/WhatsNewPopup';
import { RequestDirectBiddingAutoOpener } from '../request-settlement-agent-agreement/request-direct-bidding/RequestDirectBiddingAutoOpener';
import { DirectBiddingDisclaimerPopup } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerPopup';
import { NotificationPopover } from '../notifications/notification-list/NotificationPopover';
import { ManageMenuItem } from './ManageMenuItem';
import { KTXTradingMenuItem } from './KTXTradingMenuItem';
import { TradedPositionsCountBadge } from '../common/TradedPositionsCountBadge';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { RejectedTradesPopup } from '../rejected-trades-popup/RejectedTradesPopup';
import { user } from '../../user';
import { RequestFullDemoButton } from '../amrPipeline/RequestFullDemoPopup';
import { PortfolioAgreementDisclaimerPopup } from '../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerPopup';
import { RequestAllToAllAutoOpener } from '../request-settlement-agent-agreement/request-all-to-all-traiding/RequestAllToAllAutoOpener';
import { appConfig } from '../../app-config';
import { TrialPeriodStartBottomPanel } from '../subscription/TrialPeriodStartBottomPanel';
import { TrialExpiredBottomPanel } from '../subscription/TrialExpiredBottomPanel';
import { DisclosurePopup } from '../agreements/disclaimers/disclosure/DisclosurePopup';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

const allRolesWithoutArrangersClient = roles.getAllRolesExclude(roles.ArrangersClient);

export const Layout = props => {
    const [showInviteClientsPopup, setShowInviteClientsPopup] = useState(false);
    const contactSalesPopupVisible = useSelector(state => state.contactSales.popupVisible);
    const tradedPositionsCount = useSelector(state => state.tradeConfirm.tradedPositions.filter(p => p.trade.status === TradeStatus.pending).length);

    const isArrangersClient = user.hasSingleRole(roles.ArrangersClient);

    function active(route) {
        return props.location.pathname === route ? 'active' : '';
    }

    function isNews() {
        return matchPath(props.location.pathname, {
            path: routes.news,
            exact: true,
            strict: false
        });
    }

    function isExactNews() {
        return props.location.pathname === routes.newsUrl();
    }

    function isAllBwics() {
        const pathname = props.location.pathname;

        return pathname.includes('bwic-monitor') || pathname.includes('my-bwics')
            ? 'active' : '';
    }

    function isPortfolio() {
        return props.location.pathname
            .split('/')
            .some(path => path === 'portfolio')
            ? 'active'
            : '';
    }

    function isExactIssuanceMonitor() {
        return props.location.pathname === routes.AMRPipeline;
    }

    function pathnameStartsWth(path) {
        return props.location.pathname.includes(path);
    }

    // function isEval() {
    //     return props.location.pathname.includes(routes.eval);
    // }

    return (
        <>
            <header className="default-header">
                <nav className="navbar">
                    <Link to={routes.root} className="nav-brand">
                        <i className="icon icon-logo-bwic" />
                    </Link>

                    <ul className="navbar-menu">
                        <ShowFor roles={[...roles.bd(), ...roles.seller(), roles.DataEntry, roles.Administrator, roles.ArrangersClient]}>
                            <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                                <NavBarItem title="K-Watch News"
                                    active={!!isNews()}
                                    path={routes.newsUrl()}
                                    activeClickable={!isExactNews()}
                                />
                            </RoleActionBlocker>
                        </ShowFor>
                        <ShowFor roles={[...roles.bd(), ...roles.seller(), roles.DataEntry, roles.Administrator, roles.Viewer, roles.ArrangersClient]}>
                            <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                                <NavBarItem
                                    title="Dashboard"
                                    active={!!active(routes.dashboard)}
                                    className="navbar-link-dashboard"
                                    path={routes.dashboard}
                                />
                            </RoleActionBlocker>
                        </ShowFor>
                        <ShowFor roles={[roles.Administrator, ...roles.bd(), ...roles.seller(), roles.SellerAdministrator, roles.Viewer, roles.DataEntry, roles.ArrangersClient]}>
                            <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                                <NavBarItem
                                    title="BWIC Monitor"
                                    active={!!isAllBwics()}
                                    path={routes.allBWICs}
                                >
                                    <TradedPositionsCountBadge tradedPositionsCount={tradedPositionsCount} />
                                </NavBarItem>
                            </RoleActionBlocker>
                        </ShowFor>
                        <ShowFor roles={roles.issuanceMonitorAccess()}>
                            <NavBarItem
                                title="Issuance Monitor"
                                className="navbar-link-amr"
                                active={pathnameStartsWth('issuance-monitor')}
                                path={routes.AMRPipeline}
                                activeClickable={!isExactIssuanceMonitor()}
                            />
                        </ShowFor>
                        <ShowFor roles={roles.bd()}>
                            <NavBarItem
                                title="Inventory"
                                active={pathnameStartsWth(routes.inventory)}
                                path={routes.inventory}
                            />
                        </ShowFor>
                        <ShowFor roles={[...roles.seller(), roles.Viewer, roles.Administrator, roles.DataEntry, roles.ArrangersClient]}>
                            <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                                <NavBarItem
                                    title="Dealer Inventory"
                                    active={pathnameStartsWth(routes.inventory)}
                                    path={routes.inventory}
                                />
                            </RoleActionBlocker>
                        </ShowFor>
                        <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.CollateralManager, roles.ArrangersClient]}>
                            <RoleActionBlocker roles={[...roles.seller(), ...roles.bd(), roles.CollateralManager]}>
                                <KTXTradingMenuItem />
                            </RoleActionBlocker>
                        </ShowFor>
                        <ShowFor
                            roles={[...roles.bd(), ...roles.seller(), roles.Media]}>
                            <NavBarItem
                                title="Banks"
                                active={pathnameStartsWth(routes.manageBanks)}
                                path={routes.manageBanks}
                            />
                        </ShowFor>
                        <ShowFor
                            roles={[roles.Viewer, roles.Administrator, roles.DataEntry, roles.ArrangersClient]}
                        >
                            <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                                <NavBarItem
                                    title="Portfolio"
                                    className="navbar-link-portfolio"
                                    active={isPortfolio()}
                                    path={routes.portfolio}
                                />
                            </RoleActionBlocker>
                        </ShowFor>
                        <ShowFor roles={[roles.SellerAdministrator]} singleRole>
                            <NavBarItem
                                title="Manage"
                                className="navbar-link-address-book"
                                active={pathnameStartsWth(routes.profile)}
                                path={routes.profileManagePlatformUsers}
                            />
                        </ShowFor>
                        {/* AMR-11246 [staging] BWIC:: Hide the Eval landing page in the Main menu for 6.4 BWIC release
                        <ShowFor roles={[roles.Administrator, roles.DataEntry, roles.Viewer, ...roles.seller(), ...roles.bd()]}>
                            {!user.hasRoles(roles.Media) &&
                                <NavBarItem
                                    title="eval"
                                    className="navbar-link"
                                    active={isEval()}
                                    isNewLabelVisible={user.current().newEvalLabelActive}
                                    path={routes.eval}
                                />
                            }
                        </ShowFor> */}
                        <ShowFor roles={[roles.Administrator, roles.DataEntry]}>
                            <ManageMenuItem />
                            <NavBarItem
                                title="Trades"
                                className="navbar-link-trades"
                                active={pathnameStartsWth(routes.trades)}
                                path={routes.trades}
                            />
                        </ShowFor>
                        <ShowFor roles={[roles.CollateralManager, roles.Media, ...roles.bd(), ...roles.seller()]}>
                            <NavBarItem
                                title="CLO Managers"
                                active={pathnameStartsWth(routes.manageCloManagers)}
                                path={routes.manageCloManagers}
                                isNewLabelVisible={user.current().newCloManagerLabelActive}
                            />
                        </ShowFor>
                        <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                            <NavBarItem
                                title="resources"
                                active={pathnameStartsWth(routes.resources)}
                                path={routes.resourcesWebinarGeneral}
                            />
                        </RoleActionBlocker>
                    </ul>

                    <div className="navbar-item-right">
                        <ShowFor roles={[...roles.bd(), ...roles.seller(), roles.Viewer]}>
                            <button className="btn btn-ghost btn-invite-clients"
                                onClick={() => setShowInviteClientsPopup(true)}>
                                Invite
                            </button>
                        </ShowFor>
                        <ShowFor role={roles.ArrangersClient}>
                            <RequestFullDemoButton />
                        </ShowFor>
                        <WhatsNewPopup />
                        <UserFeedback />
                        <Support />
                        {!appConfig.notificationsDisabled && !isArrangersClient && <NotificationPopover />}
                        <ProfileMenu />
                    </div>
                </nav>
            </header>

            <main className="default-main">
                <ShowFor roles={roles.subscriptionChange()}>
                    <TrialPeriodBanner />
                </ShowFor>
                {props.children}
                {
                    !props.isError &&
                    <>
                        <ShowFor roles={[roles.BrokerDealerTrader, roles.SellerTrader]}>
                            <AffirmTradePopup />
                            <TradeRejectReasonPopup />
                        </ShowFor>
                        {showInviteClientsPopup &&
                            <InviteClientsPopup onClose={() => setShowInviteClientsPopup(false)} />}
                        {contactSalesPopupVisible && <ContactSalesPopup />}
                        <ShowFor role={roles.SellerTrader}>
                            <RequestDirectBiddingAutoOpener />
                        </ShowFor>
                        <ShowFor role={roles.BrokerDealerTrader}>
                            <RequestAllToAllAutoOpener />
                        </ShowFor>
                        <ShowFor roles={[...roles.seller(), roles.BrokerDealerTrader]}>
                            <DirectBiddingDisclaimerPopup />
                        </ShowFor>
                        <ShowFor roles={roles.bd()}>
                            <RejectedTradesPopup />
                        </ShowFor>
                        <PortfolioAgreementDisclaimerPopup />
                        <ShowFor roles={roles.subscriptionChange()}>
                            <TrialPeriodStartBottomPanel />
                            <TrialExpiredBottomPanel />
                        </ShowFor>
                        <DisclosurePopup />
                    </>
                }
            </main>
            <Footer defaultFooter />
        </>
    );
}

