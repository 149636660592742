import React, { useState } from 'react';
import moment from 'moment';
import { ManageEmailFilter } from './ManageEmailFilter';
import { useEmails } from './useEmails';
import { Table } from '../../bidding/common/table';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { constants } from '../../../constants';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import {
    ManageEmailDetailsEvent,
    ManageEmailDetails,
    ManageEmailDetailsEventStatus
} from '../../../types/management/ManageEmailDetails';
import { StickyTableRow } from '../../bidding/common/table/sticky/StickyTableRow';
import { EmptyPlaceholder, Preloader, OnHoverTooltip, CopyToClipboard } from '../../common';
import { isRequesting } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { manageEmailActions } from '../../../actions/manage-email.actions';
import { AppState } from '../../../types/state/AppState';
import { useEmailTypeOptions } from './useEmailTypeOptions';

const manageEmailDateTimeFormat = 'MM/DD/YYYY hh:mm A';

export const ManageEmails = () => {
    const dispatch = useDispatch();
    const filter = useSelector((state: AppState) => state.manageEmail.filter);
    const [shouldRequestNextPage, setShouldRequestNextPage] = useState(true);
    const [typeOptions, emailTypesRequestState] = useEmailTypeOptions();
    const { emails, requestStatus, page, totalRecordNumber } = useEmails(filter, shouldRequestNextPage, setShouldRequestNextPage);

    const onNextPageRequest = () => {
        if (!isRequesting(requestStatus)) {
            setShouldRequestNextPage(true);
        }
    }

    const getTableColumns = () => {
        const columns: IColumnDefinition<ManageEmailDetails>[] = [
            {
                columnKey: 'date',
                renderColumnHeaderContent: () => 'Date&Time (EST)',
                renderColumnContent: entity => moment.utc(entity.created).tz(constants.estTimezone).format(manageEmailDateTimeFormat),
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
            }, {
                columnKey: 'email-type',
                renderColumnHeaderContent: () => 'Email Type',
                renderColumnContent: entity => {
                    const emailType = typeOptions.find(el => el.value === entity.emailType);
                    return (
                        <OnHoverTooltip overlay={emailType && emailType.description ? emailType.description : constants.emptyPlaceholder}>
                            {emailType && emailType.description ? emailType.description : constants.emptyPlaceholder}
                        </OnHoverTooltip>
                    )
                },
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
            }, {
                columnKey: 'email-status',
                renderColumnHeaderContent: () => 'Email Status',
                renderColumnContent: () => constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-sm',
                bodyClassName: 'data-list-cell-sm',
            }, {
                columnKey: 'sender',
                renderColumnHeaderContent: () => 'Sender',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.sender}>
                        {entity.sender}
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }, {
                columnKey: 'from',
                renderColumnHeaderContent: () => 'From',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.from}>
                        {entity.from}
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }, {
                columnKey: 'copyTo',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <CopyToClipboard text={entity.to} />
                ),
                headerClassName: 'data-list-cell-icon',
                bodyClassName: 'data-list-cell-icon',
            }, {
                columnKey: 'to',
                renderColumnHeaderContent: () => 'To',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.to?.split(',').join(', ')}>
                        {entity.to}
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-xl-xxl',
                bodyClassName: 'data-list-cell-xl-xxl',
            }, {
                columnKey: 'copyCC',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <>
                        {entity.cc &&
                            <CopyToClipboard text={entity.cc?.split(',').join(', ')} />
                        }
                    </>
                ),
                headerClassName: 'data-list-cell-icon',
                bodyClassName: 'data-list-cell-icon',
            }, {
                columnKey: 'cc',
                renderColumnHeaderContent: () => 'CC',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.cc?.split(',').join(', ')}>
                        {entity.cc || constants.emptyPlaceholder}
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }, {
                columnKey: 'copyBcc',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <>
                        {entity.bcc &&
                            <CopyToClipboard text={entity.bcc?.split(',').join(', ')} />
                        }
                    </>
                ),
                headerClassName: 'data-list-cell-icon',
                bodyClassName: 'data-list-cell-icon',
            }, {
                columnKey: 'bcc',
                renderColumnHeaderContent: () => 'BCC',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.bcc?.split(',').join(', ')}>
                        {entity.bcc || constants.emptyPlaceholder}
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }
        ]
        return columns.map(c => new ColumnBuilder(c))
    }

    const renderCollapsibleItem = (email: ManageEmailDetails) => {
        const columnDefinition: IColumnDefinition<ManageEmailDetailsEvent>[] = [
            {
                columnKey: 'date',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => moment.utc(entity.timestamp).tz(constants.estTimezone).format(manageEmailDateTimeFormat),
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
            }, {
                columnKey: 'type',
                renderColumnHeaderContent: () => '',
                renderColumnContent: () => constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-lg',
                bodyClassName: 'data-list-cell-lg',
            }, {
                columnKey: 'status',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity =>
                    entity.emailEventType ? ManageEmailDetailsEventStatus[entity.emailEventType] : constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-sm',
                bodyClassName: 'data-list-cell-sm',
            }, {
                columnKey: 'sender',
                renderColumnHeaderContent: () => '',
                renderColumnContent: () => constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }, {
                columnKey: 'from',
                renderColumnHeaderContent: () => '',
                renderColumnContent: () => constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }, {
                columnKey: 'icon',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <>&nbsp;</>
                ),
                headerClassName: 'data-list-cell-icon',
                bodyClassName: 'data-list-cell-icon',
            }, {
                columnKey: 'to',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.email}>
                        {entity.email}
                    </OnHoverTooltip>
                ),
                headerClassName: 'data-list-cell-xl-xxl',
                bodyClassName: 'data-list-cell-xl-xxl',
            }, {
                columnKey: 'icon',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <>&nbsp;</>
                ),
                headerClassName: 'data-list-cell-icon',
                bodyClassName: 'data-list-cell-icon',
            }, {
                columnKey: 'cc',
                renderColumnHeaderContent: () => '',
                renderColumnContent: () => constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            }, {
                columnKey: 'icon',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <>&nbsp;</>
                ),
                headerClassName: 'data-list-cell-icon',
                bodyClassName: 'data-list-cell-icon',
            }, {
                columnKey: 'bcc',
                renderColumnHeaderContent: () => '',
                renderColumnContent: () => constants.emptyPlaceholder,
                headerClassName: 'data-list-cell-lg-02',
                bodyClassName: 'data-list-cell-lg-02',
            },
        ]

        return email.emailEvents.map(e =>
            <StickyTableRow
                key={`allocation-${e.id}`}
                rowKey={`allocation-${e.id}`}
                columns={columnDefinition.map(c => new ColumnBuilder(c))}
                dataItem={{ ...e, date: email.created }}
                context={{}}
            />
        )
    }

    return (
        <div className="container manage-emails">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Emails</h1>
                </div>
                <div className="sub-header-row type02 flex-row">
                    <ManageEmailFilter
                        filter={filter}
                        typeOptions={typeOptions}
                        emailTypesRequestState={emailTypesRequestState}
                        onFilterChange={(updatedFilter) => dispatch(manageEmailActions.setFilter(updatedFilter))}
                    />
                </div>
            </div>
            <Preloader inProgress={page === 1 && isRequesting(requestStatus)}>
                {emails.length ? (
                    <Table
                        collapsible={true}
                        className="data-list-striped"
                        columns={getTableColumns()}
                        dataItems={emails}
                        shouldRenderCollapsibleRowCallback={(email: ManageEmailDetails) => !!email.emailEvents.length}
                        renderCollapsibleItem={renderCollapsibleItem}
                        onNextPageRequest={onNextPageRequest}
                        infiniteScrollEnabled={emails.length < totalRecordNumber}
                    />
                ) : (
                    <EmptyPlaceholder />
                )}
            </Preloader>
        </div>
    )
}
